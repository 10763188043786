import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import Header from '../Header';
import Content from '../Content'
import theme from '../../themes';

const cvInfo = {
  firstName: "Ágata",
  // firstName: "A.",
  lastName: "Leuck",
  birthDay: 16,
  birthMonth: 9,
  birthYear: 1991,
  experience: [
    {
      period: "2011 - 2012",
      description: "Internship as system administrator at the publicity agency Flow Comunicação.",
    }, {
      period: "2012",
      description: "Algorithms fundamentals course undergraduate teaching assistant - UFRGS.",
    }, {
      period: "2013 - 2014",
      description: "Worked for the research group NUTED(education applied digital technology center) of the college of education developing the Planeta Rooda, a virtual learning environment for children.",
    }, {
      period: "2014 - 2015",
      description: "Working for the Intelligent Database Group developing parsers(in Java) for data exchange formats used by the petroleum industry.",
    }, {
      period: "2015 - 2015",
      description: "Working for a Artificial Intelligence research group, with artificial intelligence for games.",
    }
  ]
};

function App() {
  return (
    <div className="site__wrapper">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header info={cvInfo} />
        <Content />
      </ThemeProvider>
    </div>
  );
}

export default App;