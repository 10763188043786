import React from 'react';
import './Content.scss';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      input: '',
    }
  }

  render() {
    return (
      <div className="site-content">
        <div className="site-content__wrapper">
          <p>Under construction. Please come back later!</p>
        </div>
      </div>
    );
  }
}

export default Content;
