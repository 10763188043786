import React from 'react';
import './Header.scss';
function Header(props) {
  console.log(props);
  const { firstName, middleName, lastName } = props.info;
  return (
    <header className="site-header">
      <div className="site-header__wrapper">
        <h1 className="site-header__title">
          {[firstName, middleName, lastName].join(' ')}
          <span className="blinkingCursor"></span>
        </h1>
      </div>
    </header>
  );
}

export default Header;
